@media (max-width: 360px) {
  .calendar-name {
    max-width: 200px;
  }
}

@media (max-width: 576px) {
  .calendar-name {
    max-width: 230px;
  }
        .tip-button {
          width: 100%;
          text-wrap: wrap !important;
          text-align: left;
        }
}

@media (min-width: 576px) {
  .calendar-name {
    max-width: 400px;
  }
}